<template>
  <a :href="`/article?id=${note.id}`" target="_blank" id="card-note">
    <div class="img">
      <img :src="note.cover" alt="">
    </div>
    <div class="title">{{ note.title }}</div>
    <div class="info">{{ note.info }}</div>
    <div class="more">
      <span>{{ note.time }} / 作者 {{ note.author }}</span>
      <slot></slot>
    </div>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    note: Object
  },
  setup() {

    return {
      
    }
  }
})
</script>

<style lang="scss" scoped>
  #card-note {
    
    &:hover {
      cursor: pointer;
      
      .title {
        color: #2254f4;
      }
    }
    
    .img {
      
      img {
        width: 100%;
        height: 160px;
        border-radius: 6px;
      }
    }

    .title {
      color: #333;
      font-size: 14px;
      font-weight: bold;
      line-height: 30px;
      height: 30px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 600;
      margin-top: 10px;
    }

    .info {
      color: #999;
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .more {
      display: flex;
      justify-content: space-between;
      color: #999;
      font-size: 12px;
      margin-top: 15px;
    }
  }

  // 移动端
  @media screen and (max-width: 800px) {
    #card-note {

      .img {

        img {
          height: 170px;
        }
      }
    }
  }
</style>